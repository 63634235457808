// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

// @font-face {
//   font-family: 'larken_demoregular';
//   src: url('../fonts/larkendemo-regular-webfont.woff2') format('woff2'),
//     url('../fonts/larkendemo-regular-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'larken_demobold';
//   src: url('../fonts/larkendemo-bold-webfont.woff2') format('woff2'),
//     url('../fonts/larkendemo-bold-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'larken_demoextrabold';
//   src: url('../fonts/larkendemo-extrabold-webfont.woff2') format('woff2'),
//     url('../fonts/larkendemo-extrabold-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'larken_demolight';
//   src: url('../fonts/larkendemo-light-webfont.woff2') format('woff2'),
//     url('../fonts/larkendemo-light-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// @font-face {
//   font-family: 'larken_demomedium';
//   src: url('../fonts/larkendemo-medium-webfont.woff2') format('woff2'),
//     url('../fonts/larkendemo-medium-webfont.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;

// }

// :root {
//   --body-font: 'larken_demoregular', sans-serif;
//   --heading-font: 'larken_demobold';
// }

// p,
// a,
// body,
// label,
// input,
// button,
// h3,
// h4,
// h2 {
//   font-family: var(--body-font);
// }

.main-page {
  text-align: center;
}
body{
  position: static !important;
}
// button {
//   font-family: "larken_demoregular" !important;
// }

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

img.App-logo {
  width: 129px;
}

$website-color: #0682ca;

.text-color {
  color: $website-color;
}

.bg-color {
  $website-color: #0682ca;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*******navbar*css********/
.text-container {
  font-size: 1.875rem; /* Equivalent to text-3xl */
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue-text {
  color: #0682ca;
  font-size: 1.875rem; /* text-3xl */
  font-weight: bold;
}

.black-text {
  color: black;
  font-size: 1.875rem; /* text-3xl */
}

.gray-text {
  color: #6b7280; /* gray-500 */
  font-size: 14px; /* text-base */
  font-weight: normal;
  margin-left: 0.25rem; /* ml-1 */
  letter-spacing: 0.05em; /* tracking-wide */
  margin-top: 13px;
}

header {
  position: relative;
  z-index: 1;
}

.main-header {
  width: 84%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.container-header {
  background-color: #fff;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  padding-bottom: 0;
  padding-top: 6px;
  float: left;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  color: #fff;
}

.navigation-menu ul li a {
  color: #000;
  // text-transform: uppercase;
  font-size: 16px;
  border: none;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
  margin-bottom: 0px;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.mobile-login-btn {
  display: none;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  // padding: 0.5rem;
  border-radius: 50%;
  background-color: #0682ca;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  // position: absolute;
  // top: 50%;
  // left: 117px;
  // transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #0682ca;
}

li.user-login a {
  background: #0682ca;
  border-radius: 25px;
  border: 1px solid #0682ca;
  padding: 10px 20px 10px 20px !important;
  font-size: 16px;
  font-weight: 400;
  width: 130px !important;
  color: #fff !important;
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
}

li.sign-list a {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #fdc101;
  padding: 10px 20px 10px 20px !important;
  font-size: 16px;
  font-weight: 400;
  width: 130px !important;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin: 12px auto;
}
.menu-item {
  position: relative;
}

.menu-button {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Tailwind's space-x-2 */
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  border: none;
  background: transparent;
  font-size: 13px;
  // text-transform: uppercase;
  // font-weight: 500;
}

.menu-button:hover {
  color: #0682ca; /* Tailwind's hover:text-[#0682ca] */
}

.icon {
  font-size: 0.875rem; /* Tailwind's text-sm */
}

.product-dropdown {
  position: absolute;
  left: 0;
  margin-top: 0.5rem;
  width: 15rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  font-size: 10px;
}

.menu-item:hover .product-dropdown {
  display: block;
  opacity: 1;
}

// .dropdown-item {
//   display: block;
//   padding: 0.5rem 1rem;
//   color: #4b5563;
//   text-decoration: none;
//   transition: background-color 0.2s ease-in-out;
//   width: 100px !important;
// }
// a.dropdown-item {
//   font-size: 15px !important;
//   height: 50px !important; 
//   padding: 0.5rem 1rem !important; 
//   width: 100% !important;
// }

.dropdown-item:hover {
  background-color: #f3f4f6; /* Tailwind's hover:bg-gray-100 */
}

/********login-sign-up-btn**************/

@media screen and (max-width: 1050px) {
  .login-btn1 {
    width: 139px !important;
    padding: 10px 10px !important;
  }
}
.login-btn1 {
  background: $website-color;
  border-radius: 5px;
  border: none;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  width: 200px;
  margin-right: 14px;
  color: #fff;
}

.login-link {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.login-icon {
  width: 25px;
  height: 25px;
  margin-left: 8px;
}

button.sign-up-btn a,
button.login-btn a {
  color: #000;
  text-decoration: none;
}

button.sign-up-btn {
  background: #fff;
  border-radius: 25px;
  border: 1px solid #fff;
  padding: 10px 20px 10px 20px;
  font-size: 16px;
  font-weight: 700;
  width: 150px;
  text-transform: uppercase;
}

/********hero--banner-css*************/

.hero-section {
  background-color: $website-color;
  min-height: 100vh;
}

.text-section {
  height: 89vh;
}

.bannertext {
  font-weight: 800;
  color: #fff;
}

.text-para {
  margin-top: 20px;
  font-size: 20px;
  /* font-weight: bold; */
  // color: #fff;
  color: rgb(255 255 255 / 0.9);
}

.btn-start-now {
  background: #facc15;
  border-radius: 5px;
  border: none;
  padding: 10px 20px 10px 20px;
  font-size: 17px;
  width: 200px;
  margin-right: 14px;
  color: $website-color;
}

.dispatch-main-text {
  color: #000;
  font-size: 40px;
}

.text-dispatch {
  color: $website-color;
}

.p-dispatch {
  font-size: 15px;
}

.feature-card {
  border-bottom: 1px solid #e5e7eb;
}

.textmuted {
  font-size: 15px;
  color: #000;
}

.read-more-link {
  color: $website-color;
  text-decoration: none;
}

.icon-box {
  background: $website-color;
  color: white;
  font-size: 30px;
  padding: 4px 10px;
  border-radius: 5px;
  height: fit-content;
}

.strat-now {
  background-color: $website-color;
  padding: 70px;
}

.strat-now-head {
  font-size: 35px;
  font-weight: 800;
  color: #fff;
}

.sign-up-buton {
  padding: 10px 25px;
  border-radius: 5px;
  border: none;
  color: $website-color;
  font-size: 17px;
}

.service-section {
  background-color: #111827;
  padding: 20px;
  margin-top: 20px;
}

.textdispatch {
  color: $website-color;
}

.icon-box1 {
  width: 40px;
  height: 40px;
  background: #0682ca;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 1.5rem;
}
@media screen and (max-width: 778px) {
  .icon-box1 {
    width: 60px
  }
}

.service-button {
  background: $website-color;
  border-radius: 5px;
  border: none;
  padding: 13px;
  font-size: 16px;
  margin-right: 14px;
  color: #fff;
  // width: 150px;
}

.service-button.active {
  background-color: #056aa3;
}

.service-main-button {
  border-bottom: 1px solid rgb(55 65 81);
  padding: 0 0 27px;
  margin-top: 20px;
  margin-bottom: 70px;
}

.active-cont {
  background: rgb(31 41 55);
  border: 1px solid gray;
  border-radius: 15px;
}

.feature-card1 {
  background: white;
  color: black;
  padding: 15px;
  border-radius: 15px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.transition {
  transform: translateY(5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.transition:hover {
  transform: translateY(0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.card-choose {
  background-color: $website-color;
  color: #fff;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.card-choose::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-choose:hover::before {
  opacity: 1;
}

.bi-check-lg {
  color: rgb(34 197 94);
  font-size: 20px;
}

.our-price {
  background-color: rgb(243 244 246);
}
.card-review {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.solution-for {
  background-color: rgb(243 244 246);
  padding: 5rem;
}
.icon-solution {
  background: $website-color;
  padding: 15px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 10px;
}
.solu-butn {
  background-color: $website-color;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  color: #fff;
}
.space-y-6 > div {
  margin-bottom: 1.5rem; /* Spacing between questions */
}

.faq-container {
  border: 1px solid #e5e7eb; /* Tailwind's border-gray-200 */
  border-radius: 0.5rem; /* Tailwind's rounded-lg */
  background-color: #ffffff; /* Tailwind's bg-white */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Tailwind's shadow-md */
  overflow: hidden;
}

.faq-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  color: #374151;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.faq-toggle:hover {
  background-color: #f3f4f6;
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.faq-content {
  display: none;
  padding: 1.25rem;
  color: #4b5563;
}

.form-container {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

@media (min-width: 640px) {
  .form-container {
    flex-direction: row;
  }
}

.input-container {
  position: relative;
  width: 100%;
}

@media (min-width: 640px) {
  .input-container {
    width: auto;
  }
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.input-field {
  width: 100%;
  padding-left: 3rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.5rem;
  color: #374151;
  border: 1px solid #d1d5db;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.input-field:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px #3b82f6;
}

.enrol-btn {
  background-color: #facc15;
  color: #111827;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease-in-out;
  border: none;
  cursor: pointer;
}

.enrol-btn:hover {
  background-color: #eab308;
}
.container-contact {
  background-color: rgb(249 250 251);
}
.form-main {
  box-shadow: none !important;
  border: 1px solid rgb(209 213 219) !important;
  border-radius: 5px !important;
}
.input-text1 {
  background: none !important;
  border: none !important;
}
.form-input1 {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  border-radius: 0px !important;
}
.input-group .form-input1:focus {
  border: none !important;
  outline: none !important;
}
.form-input1:focus {
  box-shadow: none !important;
  border: none !important; /* Ensure no border appears on focus */
  outline: none !important; /* Remove the default focus outline */
  background-color: transparent !important;
}
.form-input1::placeholder {
  color: #6b7280 !important;
}
.sen-buton {
  background-color: $website-color;
  border: none;
  padding: 10px 15px 15px;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
}

.btn-pro {
  background-color: $website-color !important;
  color: #fff !important;
}

.faq-container {
  max-width: 600px;
  margin: auto;
}

.faq-card {
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border-radius: 8px;
  overflow: hidden;
}

.faq-header {
  width: 100%;
  text-align: left;
  padding: 15px;
  font-size: 16px;
  font-weight: 600;
  background: white;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.faq-header:hover {
  background: #f8f9fa;
}

.faq-body {
  padding: 15px;
  font-size: 14px;
  background: white;
}

.faq-icon {
  font-size: 18px;
  font-weight: bold;
}
.acordin-detail {
  text-align: start !important;
}
.acordin-main {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3) !important;
  padding: 7px !important;
  border-radius: 10px !important;
}
// .MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
//   text-align: start !important;
// }
// .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1086bdv-MuiPaper-root-MuiAccordion-root {
//   margin-bottom: 20px !important;
//   margin-top:20px !important;
//   box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3) ;
//   padding: 7px;
//     border-radius: 10px;
// }

.recomeneded{
  position: absolute;
    z-index: 2;
    background-color: $website-color;
    padding: 0px;
    border-radius: 10px;
    right: 275px;
    /* bottom: 0; */
    width: 109px;
}
iframe#\:1\.container{
  display: none !important;
}

/* Hide Google Translate tooltip */
#goog-gt-tt, .goog-te-balloon-frame {
  display: none !important;
  visibility: hidden !important;
}

/* Prevent text from being highlighted */
.goog-text-highlight {
  background: transparent !important;
  color: inherit !important;
  box-shadow: none !important;
}
font font:hover{
  background-color: transparent !important;
  background: transparent !important;
}
font:hover{
  background-color: transparent !important;
  background: transparent !important;
}
font .VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q{
  background-color: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
  -webkit-box-sizing: none !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display: none !important;
}
/* Hide "Powered by Google" text */
.goog-logo-link, .goog-te-gadget span, .goog-te-banner-frame, .goog-te-gadget-simple span {
  display: none !important;
  visibility: hidden !important;
}

/* Hide Google Translate bar */
.goog-te-banner-frame {
  display: none !important;
  height: 0px !important;
  visibility: hidden !important;
}

/* Remove margin Google adds */
.goog-te-gadget {
  font-size: 0 !important;
}
.goog-te-gadget .goog-te-combo{
  margin-right: 10px !important;
  padding: 5px !important;
    border-radius: 5px !important;
    font-family: "Arial", "Helvetica", sans-serif !important;
    font-weight: 600 !important;
}


/* Hide "Powered by Google" */
.goog-logo-link, .goog-te-gadget span {
  display: none !important;
  visibility: hidden !important;
}

/* Hide Google Translate banner */
.goog-te-banner-frame {
  display: none !important;
  height: 0px !important;
  visibility: hidden !important;
}

/*********about--us--section********************/
section.about-us-section {
  float: left;
  width: 100%;
}

.about {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-around;
  text-align: left;
  align-items: center;
}

.about-right h2 {
  color: #fdc101;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-color: #fdc101;
  text-decoration-style: solid;
  text-decoration-thickness: 4px;
  text-underline-offset: 10px;
}

.about-right h3 span {
  color: #fdc101;
}

.about-right h3 {
  font-size: 50px;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 15px;
  float: left;
  width: 100%;
}

.about-right p {
  text-transform: uppercase;
  font-size: 16px;
}

/*******footer---css*******************/
footer {
  float: left;
  width: 100%;
  background: #616161;
  color: #fff;
  margin-top: 50px;
  text-align: left;
  padding-bottom: 15px;
}

.footer-outer {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 30px;
}

.footer-outer h3 {
  color: #fdc101;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 400;
}

.about-footer img {
  text-align: left;
  float: left;
}

.about-footer p {
  text-transform: uppercase;
  font-size: 15px;
}

.news-footer h4 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 20px;
}

.footer-inner {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

form.newsletter-email-form input::placeholder {
  color: #fff;
  text-transform: uppercase;
}

form.newsletter-email-form input {
  background: #464646;
  color: #fff;
  border-radius: 25px;
  padding: 14px 29px 14px 20px;
  border: none;
  width: -webkit-fill-available;
}

form.newsletter-email-form {
  float: left;
  width: 100%;
}

form.newsletter-email-form input:focus {
  outline: none;
  box-shadow: none;
}

button.news-btn {
  background: #fdc101;
  color: #fff;
  border-radius: 25px;
  border: none;
  padding: 14px 22px 14px 20px;
  width: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin-top: 15px;
}

/**********login--css************/
button.custom-login.btn.btn-primary {
  background: #fdc101;
  border: none;
  text-transform: uppercase;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  display: block;
  padding: 16px;
  border-radius: 25px;
  color: #000;
  font-size: 22px;
  font-family: "larken_demoregular";
}

button.custom-login-driver.btn.btn-primary {
  background: #fdc101;
  border: none;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: end;
  padding: 8px 20px;
  border-radius: 20px;
  color: #000;
  font-size: 22px;
  font-family: "larken_demoregular";
}

.custom-login-form label {
  text-transform: capitalize;
  text-align: left !important;
  width: 100%;
  font-weight: 600;
}

.login-left-content {
  text-align: center;
}

.custom-login-form input {
  box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

.custom-login-form input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #cccccc69;
}

p.lead {
  color: #fdc101;
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 500;
}

.custom-login-form {
  overflow-x: hidden;
}

.login-left-content {
  text-align: center;
  width: 50%;
  margin: 0 auto;
}

img.login-imgg {
  width: 50%;
}

img.img-fluid-login {
  width: 73%;
  float: right;
}

.svg-outer {
  cursor: pointer;
  margin-top: 7px;
  margin-left: 13px;
}

label.form-check-label {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 300;
}

a.forgot-pwd {
  color: #000;
  text-decoration: none;
  font-size: 18px;
}

@media screen and (max-width: 1150px) {
  .banner-text {
    bottom: 5rem;
  }

  img.img-fluid-login {
    width: 100%;
    float: left;
  }
}

@media screen and (max-width: 992px) {
  .hero-banner {
    top: 0;
  }

  .mobile-login-btn {
    display: block;
  }

  .navigation-menu ul li a {
    color: #000;
  }

  .container-header {
    display: block;
    padding: 0;
  }

  .header-buttons {
    float: left;
    width: 100%;
    display: none;
  }

  .nav-bar {
    float: left;
    width: 50%;
    text-align: left;
  }

  .logo {
    float: left;
    width: 100%;
    text-align: left;
  }
  .text-container{
    width: 40%;
    justify-content: left;
  }
  .about-left img {
    width: 100%;
  }
  .nav-bar{
    width: 60%;
  }
  .menu-button{
    margin: auto;
  }
  .hamburger {
    display: block;
  }

  .banner-text {
    bottom: 64px;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    /* height: calc(100vh - 60px); */
    background-color: white;
    border-top: 1px solid black;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #cccccc61;
  }

  .mobile-login-btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .mobile-login-btn li a {
    border-bottom: 1px solid #fdbd1b;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .about {
    display: block;
    text-align: center;
  }

  .about-left,
  .about-right {
    float: left;
    width: 100%;
  }

  p.lead {
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 25px;
  }

  .login-left-content {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .navigation{
    flex-direction: column;
    align-items: end;
  }
}

@media screen and (max-width: 768px) {
  .nav-bar {
    float: left;
    width: 100%;
    text-align: left;
  }

  .logo {
    float: left;
    width: 100%;
    text-align: left;
  }
  .text-container{
    width: 40%;
  }
  .about-left img {
    width: 100%;
  }
  .nav-bar{
    width: 60%;
  }
  .navigation{
    flex-direction: column;
    align-items: end;
  }

  .hamburger {
    right: 15px;
    transform:none;
    padding: 5px;
    display: flex;
    position: static;
  }
  .menu-button{
    margin: auto;
  }
  .about-right h3 {
    font-size: 25px;
  }

  .about-right p {
    font-size: 14px;
    float: left;
    width: 100%;
  }

  .footer-outer {
    display: block;
  }

  .about-footer,
  .contact-footer,
  .news-footer {
    float: left;
    width: 100%;
    /* padding-left: 25px; */
  }

  .about-inner-text {
    padding: 10px;
  }

  /* .footer-inner {
  padding-left: 25px;
} */
  .banner-text {
    bottom: 0;
  }

  .banner-text {
    position: unset;
  }

  .banner-text {
    color: #2c2c2c;
  }

  .banner-text h4 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .footer-inner {
    display: block;
    padding-left: 25px;
    width: 90%;
  }

  .login-left-content {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  p.lead {
    font-size: 25px;
    text-align: center;
    margin-right: 0 !important;
  }

  img.login-imgg {
    width: 65%;
  }

  img.img-fluid-login {
    width: 100%;
    float: left;
    margin-top: 21px;
  }
}

@media screen and (max-width: 440px) {
  .banner-text p {
    float: none;
    width: 90%;
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 14px;
  }

  button.about-me-btn {
    margin-bottom: 20px;
  }

  .news-footer h4 {
    font-size: 16px;
    margin-bottom: 29px;
  }
}

.top-bar {
  width: 100%;
  display: flex;
  background: #fff;
  float: left;
  box-shadow: 0px 2px 2px 0px #ccc;
  padding-bottom: 11px;
}

.sidebar.nav {
  background: #cccccc30;
  height: 100vh;
  padding-top: 20px;
}

a.nav-link {
  color: #2c2c2c !important;
  font-family: "Poppins", sans-serif;
  text-decoration: none !important;
}

a.navv-link {
  color: #2c2c2c;
  text-decoration: none;
}

a.navv-link {
  font-family: "Poppins", sans-serif;
  padding-left: 20px;
  width: 90%;
  float: left;
  padding: 15px 20px 15px 20px;
}

a.navv-link.active {
  background: #a0c0f7;
  border-radius: 25px;
  /* padding: 10px 20px 10px 20px; */
}

.container-fluidd {
  padding: 0;
  overflow-x: hidden;
}

.nav-item {
  float: left;
  width: 100%;
}

.nav-item.dropdown {
  position: relative;
}

.nav-item.dropdown::after {
  content: "\25BE";
  /* Unicode character for down arrow */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 10px;
}

/* Rotate the arrow for the open dropdown */
.nav-item.dropdown.show::after {
  transform: rotate(180deg);
}

.map-outer {
  background: #fff;
  padding: 14px;
}

.card {
  border: none !important;
  border-radius: 29px;
}

.card-body.d-flex.booked-trips-card {
  background-image: linear-gradient(#b0b6ff, #c7e6ea);
  padding: 16px 13px 16px 13px;
  border: none;
  border-radius: 7px;
}

.card-body.d-flex.cancelled-trips {
  background-image: linear-gradient(#c7eae4, #fcd5ce);
  padding: 16px 13px 16px 13px;
  border: none;
  border-radius: 7px;
}

hr {
  margin: 5px 6px 14px 0px;
}

img.card-img-top {
  width: auto;
}

td.d-flex.action-icons {
  justify-content: space-evenly;
}

.card-body.d-flex.new-users {
  background-image: linear-gradient(#c7eae4, #f2d0a9);
  padding: 16px 13px 16px 13px;
  border: none;
  border-radius: 7px;
}

.card-body.d-flex.total-earinings {
  background-image: linear-gradient(#c7eae4, #e5d9f2);
  padding: 16px 13px 16px 13px;
  border: none;
  border-radius: 7px;
}

img.map-img {
  width: 100%;
}

.active-trip-outer {
  /* overflow-y: auto; */
  background: #fff;
  box-shadow: 0px 1px 4px 1px #00000040;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 20px;
}

.Access-driver-list {
  height: 580px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: #fff;
  box-shadow: 0px 1px 4px 1px #00000040;
  border-radius: 10px;
  padding: 7px;
  margin-bottom: 20px;
}

.status {
  background-image: linear-gradient(#05d41f, #26e40f);
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  color: #fff;
}

.driver-icons div {
  margin-top: 5px;
}

.driver-icons {
  border-bottom: none;
  padding-bottom: 12px;
}

.accordion-item {
  color: var(--cui-accordion-color);
  background-color: var(--cui-accordion-bg);
  border: var(--cui-accordion-border-width) solid #fdc101 !important;
  outline: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #a0c0f7 !important;
  box-shadow: inset 0 calc(-1 * var(--cui-accordion-border-width)) 0 #fdc101 !important;
}

.accordion-button:focus {
  z-index: 3;
  border-color: $website-color !important;
  outline: 0;
  box-shadow: var(--cui-accordion-btn-focus-box-shadow);
}

.MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: 800;
}

.group-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.go433304200:before {
  content: "✓";
  color: white;
  background-color: #fdc101 !important;
}

.go3842760039:before {
  content: "•";
  color: white;
  background-color: #fdc101 !important;
}

.document-heading {
  color: #212529;
  white-space: nowrap;
}

/* .gm-style-iw-d {
  height: 300px !important;
} */
button.gm-ui-hover-effect {
  display: none !important;
}

.driver-register {
  font-size: 30px !important;
  margin-bottom: 20px;
}

/* .gm-style .gm-style-iw-tc::after {
  background: #000 !important;
  -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
  clip-path: polygon(0 0, 50% 100%, 100% 0);
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: -1px;
  width: 25px;
}
.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style .gm-style-iw-c {
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(-50%, -100%, 0);
  transform: translate3d(-50%, -100%, 0);
  background-color: black !important;
  border-radius: 8px;
  padding: 0px !important;
  -webkit-box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
  box-shadow: 0 2px 7px 1px rgba(0,0,0,.3);
} */
