// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&display=swap');

$website-color: #0682ca;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

.sidebar-nav .nav-group {
    position: unset;
    float: left;
    width: 100%;
}

.sidebar.sidebar-fixed {
    background: #fff;
}

.body.flex-grow-1.px-3 {
    background: #fff;
}

.sidebar-brand.d-none.d-md-flex {
    background: #fff;
}

.sidebar-nav .nav-group-toggle::after {
    background-image: url('../assets/images/arrow.png')
}

.sidebar-nav .nav-link:hover.nav-group-toggle::after {
    background-image: url('../assets/images/arrow.png')
}

.sidebar-nav {
    box-shadow: 0px 1px 2px 0 #00000040;
}

.header.header-sticky {
    box-shadow: 0px 2px 2px 0px #00000040;
}

.header-divider {
    display: none;
}

.sidebar-brand.d-none.d-md-flex {
    background: #fff;
    box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 25%);
}

.sidebar-nav .nav-group.show {
    background: transparent;
}

// .map-outer h2 {
//     font-family: 'Poppins', sans-serif;
// }
// p.card-text h5, span, p {
//     font-family: 'Poppins', sans-serif;
// }
p.card-text h5 {
    font-size: 16px;
}

p.card-text p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
}

p.card-text span {
    font-size: 25px;
    font-weight: 600;
}

h2.head-rider {
    background: #fff;
    margin-bottom: 0;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    font-size: 22px;
}

.active-trip-outer h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;

}

.simplebar-content li.nav-item .active {
    background: #a0c0f7;
    box-shadow: 0px 1px 4px 0px #00000040;
    // padding: 8px 0px 8px 8px;
}

ul.nav-group-items li a {
    margin-top: 10px;
    margin-bottom: 10px !important;
    float: left;
    width: 100%;
}

ul.nav-group-items li a.nav-link.active {
    border-radius: 0px;
    width: 100%;
}

.sidebar-nav {
    flex-direction: column;

}

.simplebar-content li.nav-item a {
    // width: 89%;
    // margin: 0 auto;
    // border-radius: 25px;
    // margin-left: 0;
    width: 95%;
    margin: 0 auto;
    border-radius: 0;
    margin-left: 0;
    padding-top: 8px;
    padding-bottom: 10px;
}

ul.nav-group-items li a.nav-link {
    height: 50px;
}

.simplebar-content {
    margin-top: 20px;
    float: left;
    width: 100%;
    // height: 100vh;
    margin-left: 6px;
}

.simplebar-content a.nav-link.active .radius-svg {
    background-color: $website-color;
    border-radius: 100%;
    position: relative;
    box-shadow: 0px 1px 4px 0px #00000040;
    margin-right: 10px;
    padding: 8px;
    text-align: center;

}

.simplebar-content a.nav-link.active .radius-svg path {
    fill: #fff;
}

.simplebar-content .radius-svg {
    background: #FFF;
    border-radius: 100%;
    position: relative;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    margin-right: 10px;
    padding: 8px;
    text-align: center;
}

.radius-svg svg path {
    fill: $website-color;
}

.active-trip-outer table tr {
    border-bottom: 1px solid #cccccc45;
}

.right-trip-content img {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
}

.box-shd {
    box-shadow: 0px 2px 4px 0px #e2dddd;
    padding: 4px 0px 0px 14px;
    width: 100%;
    float: left;
    margin-bottom: 10px;
}

.heading-for-every-page {
    color: #666666;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
}

.balance-heading {
    color: #000;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
}

.pay-btn {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    font-size: 18px;
    padding: 10px 20px;
    // width: 140px;
    color: #fff;
    box-shadow: 0px 1px 4px 0px #00000040;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif !important;
}

// .sidebar-nav {
//     flex-direction: initial; 
// }
body::-webkit-scrollbar {
    width: 4px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.sidebar-nav::-webkit-scrollbar {
    width: 4px;
}

.sidebar-nav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.sidebar-nav::-webkit-scrollbar-thumb {
    background-color: #ccc;
    outline: none;
}

div#fare_management_page .trips-head {
    margin-bottom: 16px;
}

.add_booking,
.add_fare,
.fare_list,
.add_company_btn,
.company_list {
    background: $website-color;
    border: $website-color;
    font-family: 'Poppins', sans-serif !important;
}

.add_bookings:hover,
.add_fare:hover,
.fare_list:hover,
.add_company_btn:hover,
.company_list:hover {
    background: $website-color;
}

.table-responsive th {
    font-weight: 600;
}

.table-responsive {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400;
}

.table-responsive tr {
    border-bottom: 1px solid #cccccc73;
}

.submit-btn {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    width: 140px;
    box-shadow: 0px 1px 4px 0px #00000040;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif !important;
}

.submit-btn:hover {
    background: $website-color;
}

.cancel-btn {
    background: #a0c0f7;
    color: #000;
    border: #a0c0f7;
    margin-left: 10px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    width: 140px;
    box-shadow: 0px 1px 4px 0px #00000040;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif !important;
}

.cancel-btn:hover {
    background: #a0c0f7;
}

.form-label {
    font-family: 'Poppins', sans-serif !important;
}

.react-datepicker-wrapper {
    width: 100%;
}

.vehicle-cards .card {
    box-shadow: 0px 1px 4px 0px #00000040;
    padding: 10px;
}

.vehicle-cards .card-title.h5 {
    background: $website-color;
    color: #fff;
    text-align: center;
    padding: 14px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.vehicle-cards .card-body {
    padding: 0;
}

.vehiclemodalinfo.d-flex p {
    font-size: 15px;
    font-weight: 400;
}

.vehiclemodalinfo {
    justify-content: space-between;
    line-height: 35px;
}

.p-4.vehicle-cards .col-md-4 {
    margin-bottom: 20px;
}

.driver-icons button {
    background: transparent !important;
    border: none;
    padding: 0;
}

div#recent-trip-outer {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
}

.col-md-12.upload-file-input {
    position: relative;
}

.upload-file-input input {
    opacity: 0;
    background: #fff;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 0%);
    z-index: 99;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.files-outer {
    text-align: center;
    padding: 50px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    margin-top: 12px;
    border-radius: 10px;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.profle-pic img {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    border-radius: 100%;
}

.upload-file-input img {
    width: 100%;
    border-radius: 10px;
}

img.upload-icon {
    width: auto;
}

label.custom-file-upload {
    width: 100%;
}

.form-control:focus,
.form-select:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
}

.form-control,
.form-select {
    box-shadow: 0px 1px 4px 0px #00000040;
    border-radius: 10px;
    height: 50px;
}

.row.passenger-deails {
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 17%);
    width: 96%;
    margin: 0 auto;
    padding: 6px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.add_passenger_btn:hover {
    background: $website-color;
}

.add_passenger_btn {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
}

.remove_passenger_btn:hover {
    background: red;
}

.remove_passenger_btn {
    background: red;
    border: red;
    margin-left: 10px;
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
    justify-content: end;
    float: right;
}

.dashboard-chart img {
    width: 100%;
}

table.table.align-middle.table-hover.mb-0 tr td {
    font-size: 14px;
    align-items: baseline;
    justify-content: center;
}

.location-icons{
    font-size: 14px;
    align-items: baseline;
    justify-content: start !important;
}

.dashboard-chart {
    background: #fff;
    padding: 14px;
}

.dashboard-chart p {
    font-size: 14px;
    font-weight: 500;
}

.d-flex.chart-stats {
    justify-content: space-evenly;
}

.d-flex.chart-stats {
    justify-content: space-evenly;
    padding-top: 10px;
    margin-bottom: 6px;
    text-align: center;
}

.d-flex.chart-stats strong {
    font-size: 19px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.date_pic {
    z-index: 999;
}

.upload-file-input {
    position: relative;
    z-index: 9;
}


.body.flex-grow-1.px-3 {
    padding-top: 90px;
}

.header.header-sticky {
    position: fixed;
    width: -webkit-fill-available;
    padding-top: 6px;
}

option[default] {
    display: none;
}

label.form-check-label {
    font-family: "Poppins", sans-serif !important;
    font-weight: 400;
    font-size: 16px;
}

.three-dots img {
    width: auto;
}

.btn-logout {
    background: no-repeat;
    font-size: 14px;
    border: none;
    padding: 0;
    font-family: "Poppins", sans-serif !important;
}

.btn-logout:focus {
    background: transparent !important;
}

.btn-logout:hover {
    background: transparent !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent !important;
}

.header-drop-down ul {
    padding-bottom: 0;
}

.pending-trips-icons {
    align-items: baseline;
    justify-content: center;
}

.reject_icon {
    margin-left: 5px;
}

.allocate_accept_driver {
    background: none;
    border: none;
    padding: 0;
}

.allocate_accept_driver:hover {
    background: none !important;
}

.company-list-icons {
    justify-content: space-evenly;
}

.add_company_row {
    margin-top: 16px;
    padding-right: 0;
}

.add_company_row .col-md-6 {
    margin-right: 0 !important;
    padding-right: 0;
}

button.btn.btn-primary:active {
    background: $website-color !important;
}

.sidebar-nav .nav-link {
    font-size: 15px;
}

.driver-icons div {
    padding-bottom: 6px;
}

img.card-img-top {
    width: inherit !important;
}

.suggestion-inner {
    background: white;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 23%);
    border-radius: 0;
    padding-left: 10px;
    line-height: 30px;
    margin-top: 0;
    width: 100%;
    cursor: pointer;
}

.icons-outer {
    display: none;
}

.cards-for-icons {
    position: relative;
}

.icons-outer {
    position: absolute;
    right: 17px;
    top: 0;
}

.cards-for-icons:hover .icons-outer {
    display: block !important;
}

.icons-outer button {
    background: transparent;
    border: none;
    padding: 4px;
    margin: 0;
}

.icons-outer button:hover {
    background: transparent;
    border: none;
}

.icons-outer button:active {
    background: transparent !important;
}

// .cards-for-icons.card img.card-img-top:hover {
//     opacity: 0.6;
// }
.edit_vehicles a img {
    margin-right: 10px;
}

.edit_vehicles a {
    color: #666666;
    font-size: 25px;
    text-decoration: none;
    font-family: "Poppins", sans-serif !important;
}

.delete_vehicle_popup_outer button {
    width: 100%;
    margin-bottom: 16px;
    padding: 14px;
    font-family: "Poppins", sans-serif !important;
}

button.btn.btn-primary.cancel_popup {
    background: #fff;
    color: #000;
    border: none;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

button.btn.btn-primary.delete_popup {
    background: $website-color;
    border: none;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

.delete_vehicle_popup {
    text-align: center;
    line-height: 30px;
}

.delete_vehicle_popup h2 {
    font-family: "Poppins", sans-serif !important;
    margin-top: 27px;
    margin-bottom: 30px;
    font-weight: 600;
}

button#btn_edit_vehicle:active,
button#btn_delete_vehicl:active,
button#delete_company_btn:active,
button#edit_company_btn:active {
    background: none !important;
}

button#delete_company_btn,
button#edit_company_btn {
    background: transparent;
    border: none;
    padding: 0;
}

.vehicle_inner,
.image-container {
    width: 100%;
}

.pagination-outer {
    align-items: center;
    justify-content: end;
    margin-top: 16px;
    padding-bottom: 10px;
}

.pagination-outer ul li {
    list-style-type: none;
}

.pagination-outer button {
    border: none;
    background: transparent;
    font-family: "Poppins", sans-serif !important;
}

.previous-page ul li {
    float: left;
    display: contents;
}

.previous-page ul {
    margin-bottom: 0;
    padding-right: 8px;
    padding-left: 8px;
}

li.active_btn button {
    background: #000;
    color: #fff;
    border-radius: 100px;
    height: 24px;
    width: 24px;
    font-size: 14px;
    padding-top: 3px;
}

li.unactive_btn button {
    background: #cccccca6;
    color: #000;
    border-radius: 100px;
    height: 24px;
    width: 24px;
    font-size: 14px;
    padding-top: 3px;
}

.previous-page ul li button {
    margin: 4px;
    margin-top: 0;
    margin-bottom: 0;
}

// .booked-trips-outer img {
//     width: auto !important;
// }

h1.heading-for-every-page a img {
    margin-right: 10px;
}

h1.heading-for-every-page a {
    color: #666666;
    font-size: 25px;
    text-decoration: none;
    font-family: "Poppins", sans-serif !important;
}

@media screen and (max-width: 768px) {
    img.card-img-top {
        width: auto !important;
    }
}

.vehicle_inner {
    position: relative;
    width: 100%;
    /* Adjust the width as needed */
}

.image-container {
    position: relative;
}

.icons-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: start;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the overlay background color and opacity */
    opacity: 0;
    transition: opacity 0.3s;
}

.vehicle_inner:hover .icons-outer {
    opacity: 1;
}

.overlay {
    text-align: right;
    color: white;
    margin-top: 10px;
}

//   .edit_vehicle,
//   .delete_vehilce {
//     margin: 0 10px;
//   }
input#flexSwitchCheckDefault:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #bcc1c6;
}

.form-switch .form-check-input:checked {
    background-color: green;
    border-color: green;
}

.form-switch .form-check-input {
    width: 3em;
    height: 1.3em;
    margin-top: 0.1em;
}


// .form-switch .form-check-input:focus {
//     background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23998fed%27/%3e%3c/svg%3e');
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
// }
ul.nav-group-items li {
    height: 50px;
    margin-bottom: 2px;
    background: #efefef;
}

button#btn_delete_pending_trip {
    background: transparent;
    border: none;
    padding: 0;
    border-bottom: 0;
}

td.pending-trips-icons div {
    display: inline-block;
    padding: 0;
    margin: 0;
}

div#pwd_field {
    position: relative;
}

.eye_pwd_icon {
    position: absolute;
    top: 45px;
    right: 49px;
}

.simplebar-content-wrapper .simplebar-content ul:last-child li:last-child {
    margin-bottom: 20px;
}

a.vehicle_linked {
    text-decoration: none;
    color: #000;
}

.simplebar-content li:last-child {
    margin-bottom: 14px;
}

.col-sm-12.radio_inner {
    margin-top: 12px;
}

.rider-img img.avatar-img {
    height: 100%;
}

div#recent-trip-outer {
    background: #fff;
    box-shadow: 0px 1px 4px 1px #00000040;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.edit_company_popup .modal-dialog {
    width: 100%;
    max-width: 60%;
}

.card-body a {
    text-decoration: none;
    text-transform: capitalize;
}

img.vehicle_img {
    width: -webkit-fill-available;
    margin-top: 20px;
    border-radius: 8px;
}

.col-md-6.vehicle_info_right {
    margin-top: 33px;
    line-height: 40px;
    padding-left: 4rem;
}

.vehicle_info_right .form-label {
    font-weight: 600 !important;
    font-size: 18px;
    padding-right: 10px;
}

span.vehicle_info {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    padding-left: 10px;
    margin-bottom: 14px;
}

.pdf_doc {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    height: 100%;
}

.pdf_doc p {
    margin-bottom: 0;
    font-weight: 600;
    background: #cccccc4f;
    padding: 13px;
    border-radius: 9px;
}

button.remove-btn {
    background: #a0c0f7;
    color: #000;
    border: #a0c0f7;
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    padding: 10px 10px 10px 10px;
    width: 50px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
}

.driver_img_outer {
    float: left;
    width: 100%;
    display: flex;
    align-items: start;
    position: relative;
}

button.remove-btn img {
    border-radius: 23px;
}

.driver_img_outer button.remove-btn {
    position: ABSOLUTE;
    RIGHT: 4px;
    TOP: 8px;
    WIDTH: 30PX;
    border-radius: 100px;
    height: 30px;
    text-align: center;
    padding: 0;
    font-size: 18px;
}

ul.header-nav.top-bar-right li::after {
    display: none;
}

ul.header-nav.bell-icon li a {
    margin-top: 8px;
}

div#menu- {
    top: 0px;
    height: 300px;
}

#demo-simple-select:focus {
    outline: none;
    box-shadow: none;
}

#demo-simple-select {
    padding: 13px;
    border-radius: 31px !important;
}

.MuiInputBase-root.MuiOutlinedInput-root:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    margin-top: 6px;
    height: 45px;
}

.filter-outer {
    text-align: right;
}

button#dropdown-basic {
    border: none;
    padding: 0;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    background: rgba(204, 204, 204, 0.3294117647) !important;
    padding: 8px 10px 8px 10px;
    color: #000;
}

button#dropdown-basic img {
    width: 20px;
    margin-right: 6px;
}

.filter-outer {
    text-align: right;
    border-bottom: 1px solid #cccccc4d;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: end;

}

a.dropdown-item {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    border-bottom: 1px solid #cccccc63;
}

a.dropdown-item:last-child {
    border-bottom: none;
}

.booked-trips-outer img.card-img-top {
    width: auto !important;
}

// .active-trip-outer .table-responsive {
//     white-space: nowrap;
// }
// .hotel-stats {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }
.table-responsive {
    white-space: nowrap;
    cursor: pointer;
}

/* Custom styles for the table scroll bar */
.table-responsive {

    /* For webkit-based browsers (Chrome) */
    &::-webkit-scrollbar {
        width: 2px;
        /* Set the width of the scrollbar */
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $website-color;
        /* Set the color of the scrollbar thumb */
        border-radius: 1px;
        /* Round the edges of the scrollbar thumb */
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        /* Set the color of the scrollbar track */
    }
}

.vehicle_info_right img {
    margin-right: 16px;
}

div#recent-trip-outer .table-responsive td {
    white-space: break-spaces;
    width: 200px;
    max-width: 200px;
}

button#delete_driver_btn {
    padding-left: 3px;
}

div#forgot-outer {
    margin-top: 10px;
    margin-bottom: 45px;
}

a.back-to-login {
    text-align: center;
    width: 150px;
    margin-top: 24px;
    color: #000;
    font-family: "Poppins", sans-serif !important;
    text-decoration: none;
    // text-decoration-color: #fdbd1b;
    // text-decoration-style: solid;
    // text-decoration-thickness: 3px;
    // text-underline-offset: 10px;
}

.mb-4.bak-to-login-btn {
    margin-top: 40px;
}

a.back-to-login img {
    width: 8px;
    margin-right: 9px;
}

img.lock-img {
    width: 40px;
    margin-right: 10px;
}

.opt-outer input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.opt-outer input {
    width: 68% !important;
    height: 80px;
    max-width: 64% !important;
    border: none;
    border-radius: 14px;
    margin-right: 25px;
}

.opt-outer-login input:focus {
    outline: none !important;
    box-shadow: none !important;
}

.opt-outer-login input {
    width: 80% !important;
    height: 90px;
    max-width: 80% !important;
    border: none;
    border-radius: 7px;
    margin-right: 7px;
}

div#completed-trips .table-responsive td {
    width: 200px;
    max-width: 200px;
    white-space: break-spaces;
}

div#pending-trips .table-responsive td {
    max-width: 400px;
    width: 400px;
    white-space: break-spaces;
}

button#allocate_driver:active {
    background: transparent !important;
}

.reject_icon img {
    margin-top: 4px;
    margin-left: 3px;
}

button#btn_delete_fare img {
    margin-left: 4px;
    margin-top: 3px;
}

button#delete_company_btn {
    margin-left: 4px;
    margin-top: 4px;
}

button.search-btn {
    border: none;
    background: $website-color;
    border-radius: 0.25rem;
}

button.search-btn i {
    color: #fff;
}

ul.header-nav.top-bar-right ul li a {
    padding-left: 10px;
    padding-right: 16px;
}

ul.header-nav.top-bar-right ul {
    margin-top: 13px !important;
    min-width: 100px;
}

.profile_name_text {
    margin-left: 20px;
}

.profile_img img {
    border-radius: 100px;
}

.edit_profile_user {
    align-items: center;
    margin-left: 20px;
}

button#hotel_booking_link {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
}

button#hotel_booking_link:active {
    background: $website-color;
    border: $website-color;
}

.bookiing_btn {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}

.submiit-btn {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    padding: 10px 10px 10px 10px;
    width: 140px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
}

.submiit-btn:hover {
    background: $website-color;
    border: $website-color;
}

.canceel-btn:hover {
    background: #a0c0f7;
}

.canceel-btn {
    background: #a0c0f7;
    color: #000;
    border: #a0c0f7;
    margin-left: 10px;
    padding: 10px 10px 10px 10px;
    width: 140px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
}

div#profile_img_outer {
    position: relative;
}

div#profile_img_outer input {
    opacity: 0;
    z-index: 9;
    position: relative;
}

div#profile_img_outer span {
    position: absolute;
    top: 0px;
    color: #ffcc54;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #ffcc54;
    text-decoration-style: solid;
    text-decoration-thickness: 3px;
    text-underline-offset: 8px;
}

a.dropdown-item.edit_profile a {
    background: transparent;
    border: none;
    padding-right: 0;
    padding-left: 0 !important;
    text-decoration: none;
    color: #000;
}

div#company-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
}

div#company-search input.form-control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

div#company-search button.search-btn {
    padding: 13px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

div#customers-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
}

div#customers-search input.form-control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

}

div#customers-search button.search-btn {
    padding: 13px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

div#recent-trip-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 0px;
}

div#recent-trip-search input.form-control {

    height: 40px;
}

div#recent-trip-search button.search-btn {
    padding: 8px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
}

div#recent-trip-search {
    margin-right: 14px;
}

.change_pwd_btn {
    background: $website-color;
    border: $website-color;
    margin-left: 0;
    padding: 10px 10px 10px 10px;
    width: auto;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
    margin-top: 16px;
}

.change_pwd_btn:hover {
    background: $website-color;
    border: $website-color;
}

button#rest_btn:hover {
    background: $website-color;
    border: $website-color;
}

button#rest_btn {
    background: $website-color;
    border: $website-color;
    margin-left: 0;
    padding: 10px 10px 10px 10px;
    width: auto;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
    margin-top: 16px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}

button#confirmation_btn {
    background: $website-color;
    border: none;
}

button#confirmation_btn:hover {
    background: $website-color;
    border: none;
}

button#confirmation_btn:active {
    background: transparent !important;
    border: none;
}

.company-list-icons button#edit_company_btn {
    margin-top: 5px;
}

span.back_to_home {
    text-align: left;
    width: 100%;
    float: left;
    margin-top: 13px;
    color: #000;
}

span.back_to_home:hover {
    text-decoration: underline;
}

span.back_to_home img {
    padding-right: 4px;
    margin-top: -2px;
}

.booking-content {
    width: 100%;
    float: left;
    background: linear-gradient(to bottom, $website-color 0, $website-color 50%, $website-color 100%);
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.booking-content .banner-inner-text {
    top: 35px;
    width: 42%;
    box-shadow: 0 9px 0 0 #fff, 0 -9px 0 0 #fff, 7px 0 15px -4px rgb(0 0 0 / 30%), -7px 0 15px -4px rgb(0 0 0 / 30%);
    margin: 0px auto;
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    position: relative;
}

.booking-content .banner-inner-text::before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #fff;
    bottom: -24px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}

.booking-content .banner-inner-text h3 {
    font-family: "Poppins", sans-serif !important;
    font-size: 29px;
    color: $website-color;
    font-weight: 700;
    border-bottom: 1px solid #cccccc52;
    margin-bottom: 12px;
    padding-bottom: 17px;
    padding-top: 20px;
}

div#bookimg-header .col-md-6 label {
    text-align: left;
    width: 100%;
}

.confirm_boking_btn {
    background-color: $website-color;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    padding: 6px 15px;
    box-shadow: 6px 6px 0 #efe9e9;
    transition: .2s;
    border-radius: 0;
    font-family: "Poppins", sans-serif !important;
    border: none;
}

section.booking-section-form {
    // background: linear-gradient(to bottom, #ffbf00 0, rgba(255, 191, 0, 0.8) 50%, #ffbf00 100%);
    float: left;
    height: 100%;
}

.booking-footer footer {
    margin-top: 0;
}

.about-left,
.about-right {
    width: 50%;
    float: left;
}

.about-left img {
    width: 100%;
}

button#confirmation_btn a {
    font-family: "Poppins", sans-serif !important;
    color: #ffc859;
}

.change_your_pwd_txt button:hover {
    background: transparent;
    border: none;
    color: #000;
}

.change_your_pwd_txt button {
    background: transparent;
    border: none;
    font-weight: 900;
    text-decoration: underline;
    color: #2c384af2;
    font-size: 16px;
}

.change_your_pwd_txt {
    text-align: right;
}

.link_expired img {
    width: 30%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.link_expired {
    width: 80%;
    margin: 30px auto;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
    border-radius: 10px;
    background: #fff;
}

.link_text strong {
    font-size: 24px;
    font-family: "Poppins", sans-serif !important;
}

.link_text p {
    padding-bottom: 22px;
    padding-top: 12px;
}

div#expired_outer {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, $website-color 0, $website-color 50%, $website-color 100%);
}

ul.header-nav.top-bar-right ul li {
    border-bottom: 1px solid #cccccc70;
    padding-bottom: 6px;
    padding-top: 6px;
}

.view_details_btn {
    background: $website-color;
    border: $website-color;
    margin-left: 10px;
    font-size: 14px;
    padding: 8px 6px 8px 6px;
    // width: 110px;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 25%);
    border-radius: 10px;
    font-family: "Poppins", sans-serif !important;
    text-align: center;
    margin: 0 auto;
    color: #fff !important;
}

.view_details_btn:hover {
    background: $website-color;
    border: $website-color;
}

.single-trip-map iframe {
    height: 310px;
}

.view_details_btn a {
    font-family: "Poppins", sans-serif !important;
    color: #fff;
    text-decoration: none;
}

.trip-details label {
    float: left;
    width: 40%;
}

.trip-details span {
    float: left;
    width: 60%;
}

.trip-details {
    float: left;
    width: 100%;
    text-align: left;
}

form.row.g-3.view_single_trip_form {
    display: flex;
    justify-content: center;
    align-items: center;
}

.booked-trip-icon {
    text-align: center;
}

div#pending-trip-search .input-group,
div#pending-booked-search .input-group,
div#cancelled-trip-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
}

div#approved-trip-search .input-group,
div#active-trip-search .input-group,
div#complete-trip-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
}

div#booked-trip-search .input-group {
    width: 100%;
    justify-content: end;
    margin-bottom: 20px;
}

div#pending-trips {
    margin-bottom: 20px;
}

/****pankaj CSS*******/
.cancelledtrips a,
.booked-trips a,
.newUsers a {
    text-decoration: none !important;
}

.all_same .card {
    min-height: 150px;
}

.all_same_stats .card {
    min-height: 150px;
    height: 150px;
}

div#recent-trip-outer .table-responsive td div {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    height: 45px;
}

.verify-icon {
    color: #0d6efd !important;
}

.verify-icon2 {
    color: $website-color !important;
}

.vehiclemodalinfo {
    justify-content: inherit;
    line-height: 35px;
}

.vehiclemodalinfo .vehicle-title {
    width: 47%;
}

.vehiclemodalinfo .hypen {
    width: 5%;
}

.vehiclemodalinfo .vehicle-title-info {
    width: 47%;
    text-align: left;
}

.vehicle-title-info p {
    white-space: nowrap;
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vehicle-title p {
    white-space: nowrap;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
}

div#recent-trip-outer .table-responsive td .view_details_btn {
    height: auto;
}

.asterisk-mark {
    color: red;
    font-size: 20px;
}

// .text-danger::after {
//     content: "*";
//     color: red;
// }
@media screen and (max-width: 768px) {
    .booking-content .banner-inner-text {
        width: 100%;
    }

    .booking-content .banner-inner-text {
        margin-top: 20px;
    }

    .about-left,
    .about-right {
        width: 100%;
        float: left;
    }
}

span.welcome_line {
    color: $website-color;
    font-weight: 700;
    text-transform: capitalize;
}

button#edit_company_btn:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
}

button#btn_delete_pending_trip img {
    margin-top: 0px;
}

td.d-flex.pending-trips-icons {
    border-bottom: 0px;
}

/****css only for mozilla*******/
@-moz-document url-prefix() {
    .header.header-sticky {
        width: -moz-available;
    }
}

.approve-butn {
    border: none;
    border-radius: 10px;
    background-color: #fdc101;
    padding: 10px 10px;
    font-size: 16px !important;
    font-weight: 600;
}

.vichle-name {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: black !important;
}

.driver-name {
    color: black !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.trip_from {
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
}

.trip_detail {
    color: #646464c4;
    font-weight: 400;
}

.next_back {
    display: flex;
    justify-content: end;
}

.success_icon {
    width: 50px !important;
}

.success_title {
    font-size: 20px;
    font-weight: 600 !important;
}

.chart_div {
    border-radius: 21px;
    background-image: linear-gradient(#C7EAE4, #E5D9F2);
    width: 94%;
}

.select_custom {
    background-color: #a0c0f7;
    color: black;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}

.select_custom2 {
    background-color: #c8e9e4;
    color: black;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}

.input_custom {
    background-color: #a0c0f7;
    color: black;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}


.input_custom2 {
    background-color: #c8e9e4;
    color: black;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}



// .pagination {
//     display: flex;
//     justify-content: end;
//     align-items: center;
//     margin-top: 20px;
// }

.pagination li {
    list-style: none;
    margin: 0 5px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 1px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
}

// .pagination .selected {
//     background-color: $website-color;
//     color: #fff;
//     border: 1px solid $website-color;
// }

// .pagination li:hover {
//     background-color: $website-color;
//     color: #fff;
//     border: 1px solid $website-color;
// }


.pagination .previous {
    background-color: $website-color !important;
    border: 1px solid $website-color !important;
}

.pagination .previous a {
    color: white !important;
    font-weight: 900 !important;

}

.pagination .next {
    background-color: $website-color !important;
    border: 1px solid $website-color !important;
}

.pagination .next a {
    color: white !important;
    font-weight: 900 !important;

}

.pagination {
    display: flex;
        justify-content: end;
        align-items: center;
        margin-top: 20px;
}

.pagination-item {
    list-style: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 1px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
}

.pagination-link {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: black;
}

.active-page {
    background-color: #007bff !important;
    color: white !important;
    font-weight: bold !important;
    border-color: #007bff !important;
}




.badge-success {
    background: linear-gradient(90deg, $website-color 0%, rgb(56 214 235 / 62%) 100%);
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 6px;
}

.badge-success2 {
    background: linear-gradient(90deg, rgb(255, 83, 112) 0%, rgb(255, 134, 154) 100%);
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    padding: 6px;
}

.autocomplete-dropdown {
    border: 1px solid #ddd;
    max-height: 100px;
    overflow-y: auto;
    position: absolute;
    z-index: 1000;
    background: #fff;
    width: 37%;
}

.head_veh {
    font-weight: 600;
    font-size: 14px;
    // font-family: cursive !important;
}

.head_veh2 {
    font-weight: 500;
    font-size: 14px;
    // font-family: cursive !important;
}

.Driver_list {
    border: 1px solid;
    border-radius: 12px;
    height: 100px;
    overflow: auto;

}

.Driver_list li {
    padding: 3px 0px 3px 10px;
    cursor: pointer;
}

.Driver_list li:hover {
    background-color: $website-color;
}

.packge {
    border: 1px solid $website-color;
    border-radius: 32px;
    padding: 5px 0px;
    width: 89px;
    cursor: pointer;
}

.chng_pack {
    background-color: $website-color;
    border-radius: 32px;
    padding: 7px 14px 8px;
    color: white;
}

.form_pack {
    padding: 6px;
    color: $website-color;
}

.popular {
    width: 50%;
    margin: auto;
    text-align: center;
    position: relative;
    bottom: 35px;
    padding: 8px;
    border-radius: 24px;
    background-color: #d1e4fb;
    color: #005ece;
}

.plans_div {
    border: 1px solid $website-color;
    border-radius: 30px;
    padding: 18px;
    height: 420px;
    margin-top: 20px;
    cursor: pointer;

    h2 {
        font-family: sans-serif;
        margin-top: 10px;
    }
}

.plan_Active {
    color: white;
    background-color: $website-color;
    margin-top: 0px !important;
}

.plan_simple {
    color: black;

}

.buy_simple {
    width: 100%;
    border: 1px solid $website-color;
    border-radius: 10px;
    margin-top: 15px;
    background-color: $website-color;
    color: white;
    padding: 12px;
}

.buy_simple:hover {
    width: 100%;
    border: 1px solid $website-color;
    border-radius: 10px;
    margin-top: 15px;
    background-color: $website-color;
    color: white;
    padding: 12px;
}


.buy_active:hover {
    width: 100%;
    border: 1px solid $website-color;
    border-radius: 10px;
    margin-top: 15px;
    background-color: white;
    color: $website-color;
    padding: 12px;
}

.buy_active {
    width: 100%;
    border: 1px solid $website-color;
    border-radius: 10px;
    margin-top: 15px;
    background-color: white;
    color: $website-color;
    padding: 12px;
    font-weight: 700;
    font-size: 18px;
}

.hr_simple {
    background-color: $website-color;
    height: 2px;
    margin-top: 20px;
}

.hr_active {
    background-color: white;
    height: 2px;
    margin-top: 20px;
}

.offers {

    margin-top: 10px;

    ul {


        li {
            padding: 7px;
            font-size: 15px;

        }
    }
}

.payment_modal {
    margin-top: 0.5rem !important;
    color: $website-color;
    font-size: 22px;
    font-weight: 600;
    // margin-left: 86px;
}

.modal-input {
    padding: 9px;
    height: 34px;
    border: 1px solid black;
    border-radius: 6px;


}



.label_exp {
    display: inline-block;
    margin-top: 6px;
    margin-right: 39px;
    color: black;
    font-size: 13px;
}

.label_cvc {
    display: inline-block;
    margin-top: 6px;
    margin-right: 50px;
    color: black;
    font-size: 13px;
}

.cvc_number {
    font-size: 10px;
    width: 360px;
    margin-left: 9px;
    color: black;
}


.btn_pay {
    background-color: $website-color;
    color: white;
    border: 1px solid $website-color;
}

.btn_pay:hover {
    background-color: $website-color;
    color: white;
    border: 1px solid $website-color;
}

.btn_cancel_no {
    background-color: white;
    color: $website-color;
    border: 1px solid $website-color;
    margin-left: 10px;
    width: 30%;
}

.btn_cancel_no:hover {
    border: 1px solid $website-color;
    background-color: white;
    width: 30%;
    color: $website-color;
}

.btn_cancel {
    background-color: $website-color;
    color: white;
    border: 1px solid $website-color;
    margin-left: 10px;
    width: 30%;

}

.btn_cancel:hover {
    border: 1px solid #fff;
    background-color: $website-color;
    width: 30%;
    color: #fff;
}

.pln_prd {
    margin-top: 30px;
    border: 1px solid $website-color;
    padding: 6px;
    border-radius: 12px;
    background-color: $website-color;
    color: white;
    font-size: 17px;
    font-weight: 600;
}

.IBAN_elem {
    padding: 15px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
    border-radius: 10px;
    height: 50px;
}

.switches {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    color: $website-color;;
    cursor: pointer;
}

.add_border {
    border-bottom: 3px solid $website-color;
    padding-bottom: 5px;
}

.no_border {
    border-bottom: none;
    padding-bottom: 5px;
}

// .pay_modal{
//     height: 340px;
//     max-height: 420px;
// }

.summary{
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}

.cost_data{
    font-size: 12px;
    font-weight: 600;
}


.invoice_head{
    font-size: 25px;
    font-weight: 700;
}

.invoice_log{
color: grey;
}

.invoice_detail p:first-child , .invoice_details p:first-child{
    font-weight: 700;
    font-size: 15px;
    color: black;
}

.invoice_detail p ,  .invoice_details p {
    font-size: 14px;
    font-weight: 600;
}

.fnt_size{
    font-size: 13px;
    color: #000;
}

.hr_new{
    color: black;
   
}

.fnt_size2{
    font-size: 15px;
    color: #000;
}

.fnt p:last-child{
    font-weight: 700;
}

#passengerCountDisplay{
    width: 60%;
    text-align: center;
}